import {useState} from 'react';
import './App.css';

import { Authenticator } from '@aws-amplify/ui-react';
import Amplify, {API,Auth,PubSub } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import AWS from 'aws-sdk';
import awsmobile from './aws-exports';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import AirIcon from '@mui/icons-material/Air';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

//import { AWSIoTProvider } from '@aws-amplify/pubsub';
/*
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'us-east-1',
  aws_pubsub_endpoint: 'wss://aubdr1ol4p6sa-ats.iot.us-east-1.amazonaws.com/mqtt',
}));


  PubSub.subscribe('fan/pub').subscribe({
    next: data => console.log('Message received', data),
    error: error => console.error(error),
    complete: () => console.log('Done'),
  });

*/

AWS.config.update({
  region: awsmobile.aws_cognito_region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsmobile.aws_cognito_identity_pool_id
  })
});

function App() {
  const [cognitoInfo,setCognitoInfo] = useState([]);

  Auth.currentCredentials().then((info) => {
   setCognitoInfo(info);
   //console.log(info);
  });

  const lambda = new AWS.Lambda({
    credentials: Auth.essentialCredentials(cognitoInfo)
  });

  function publishMessage(frequency,protocol,message,bits) {
        lambda.invoke({
            FunctionName: 'fancontrolpassthru-dev',
            Payload: JSON.stringify(
              { 
                frequency: frequency,
                protocol: protocol,
                message: message,
                bits: bits,
              }
            ),
          },
          function(err, data) {
            if (err) {
             console.log(err, err.stack);
            }
            else {
             console.log(data);
            }
          }
        );      
  }
  
  
  return (
    <Authenticator>
    {({ signOut, user }) => (
      <div>
        <h3>Hello {user.username} {cognitoInfo&&cognitoInfo.identityId}</h3>
        <Grid container>
          <Grid item xs={12}>
            <h1>Living Room</h1>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16777109,24)} aria-label="Lights" color="primary" >
              <EmojiObjectsIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16776973,24)} aria-label="Power" color="primary" >
              <PowerSettingsNewIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16777189,24)} aria-label="Variable Speed" color="primary" >
              <AirIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16777093,24)} aria-label="Speed 1" color="primary" >
              <LooksOneIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16777029,24)} aria-label="Speed 2" color="primary" >
              <LooksTwoIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16777157,24)} aria-label="Speed 3" color="primary" >
              <Looks3Icon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16776997,24)} aria-label="Speed 4" color="primary" >
              <Looks4Icon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16777125,24)} aria-label="Speed 5" color="primary" >
              <Looks5Icon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(315,6,16777061,24)} aria-label="Speed 6" color="primary" >
              <Looks6Icon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <h1>Bedroom</h1>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(304,11,766,12)} aria-label="Lights" color="secondary" >
              <EmojiObjectsIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(304,11,701,12)} aria-label="Power Off" color="secondary" >
              <PowerSettingsNewIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(304,11,695,12)} aria-label="Speed 1" color="secondary" >
              <LooksOneIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(304,11,687,12)} aria-label="Speed 2" color="secondary" >
              <LooksTwoIcon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => publishMessage(304,11,671,12)} aria-label="Speed 3" color="secondary" >
              <Looks3Icon sx={{fontSize:60}}/>
            </IconButton>
          </Grid>
        </Grid>
        
      </div>
    )}
  </Authenticator>
  );
}

export default App;
